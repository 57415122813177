.issue-description {
    text-align: center;
    background: rgb(226, 226, 226);
    border: 1px solid rgb(183, 255, 155);
    padding: 1%;
    border-radius: 10px;
}

.issue-description__value {
    min-height: 122px;
    width: 100%;
    background-color: #e2e2e2;
    border: 1px solid gray;
    text-align: left;
    white-space: pre-line;
    padding: 5px;
}

.issue-description__textarea {
    width: 100%;
}

.issue-description__button {
    margin-top: 16px;
    width: 15%;
    height: 10%;
    border-radius: 5px;
    background: rgb(16, 110, 252);
    color: white;
}